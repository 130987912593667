import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    accountNumber: {
      type: MDinput,
      props: {
        caption: 'hermes.accountNumber',
        type: 'number',
        precision: 0,
        max: 2100000000,
        required: true,
        showThousandSeparator: false
      },
      rules: [{ validator: validatorRequire }]
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
